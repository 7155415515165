export type QuestionsProps = {
  questionNumber: number;
  topic: string;
  description: string;
  option: [string, string, string, string];
  Answer: string;
};

export const cantonVoiceTopic: string = "個人化的廣東話語音合成技術";

const Questions: QuestionsProps[] = [
  {
    questionNumber: 1,
    topic: "第一題",
    description:
      "研究團隊是利用那一項技術開發個人化的廣東話語音合成技術？",
    option: [
      "A:	人工智能技術",
      "B:	第五代流動通訊技術",
      "C:	區塊鏈技術",
      "D:	物聯網技術",
    ],
    Answer: "A",
  },
  {
    questionNumber: 2,
    topic: "第二題",
    description:
      "合成聲音會載於那處，以便使用者在日常生活中使用？",
    option: ["A:	筆記", "B:	字典", "C:	手機應用程式", "D:	光碟"],
    Answer: "C",
  },
  // {
  //   questionNumber: 3,
  //   topic: "第三題",
  //   description: "這套語音合成技術可讓那些朋友受惠？",
  //   option: [
  //     "A:	老師",
  //     "B:	喪失說話能力的病人",
  //     "C:	學生",
  //     "D:	長期病患者",
  //   ],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 4,
  //   topic: "第四題",
  //   description: "使用者需要輸入那些資料，便能以自己的聲音播出想說的話？",
  //   option: ["A:	程式碼", "B:	影像", "C:	電話號碼", "D:	文字"],
  //   Answer: "D",
  // },
  // {
  //   questionNumber: 5,
  //   topic: "5.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 6,
  //   topic: "6.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 7,
  //   topic: "7.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 8,
  //   topic: "8.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 9,
  //   topic: "9.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 10,
  //   topic: "10.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
];

export default Questions;

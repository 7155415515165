export type QuestionsProps = {
  questionNumber: number;
  topic: string;
  description: string;
  option: [string, string, string, string];
  Answer: string;
};

export const hydrogelTopic: string = "對抗肥胖的健康食油";

const Questions: QuestionsProps[] = [
  {
    questionNumber: 1,
    topic: "第一題",
    description:
      "肥胖及相關疾病與以下哪一個飲食習慣有關？",
    option: [
      "A:	高蛋白質",
      "B:	高鈉",
      "C:	高纖維",
      "D:	高油高脂",
    ],
    Answer: "D",
  },
  {
    questionNumber: 2,
    topic: "第二題",
    description: "以下哪一個物質可以減少脂肪在人體內積累？",
    option: ["A:	甘油二酯", "B:	甘油三酯", "C:	酶", "D:	水凝膠"],
    Answer: "A",
  },
  // {
  //   questionNumber: 3,
  //   topic: "第三題",
  //   description: "以下哪一項正確排序了水凝膠微膠改善肥胖人士生活質量的過程？\n(1) 甘油二酯的生物合成效率得以提升\n(2) 三相催化體系形成\n(3) 水凝膠微膠協助身體從食物中吸收更多脂肪酶\n(4) 水凝膠微膠有效固定脂肪酶",
  //   option: [
  //     "A:	（3），（1），（2）",
  //     "B:	（3），（4），（2）",
  //     "C:	（4），（2），（1）",
  //     "D:	（4），（1），（2）",
  //   ],
  //   Answer: "C",
  // },
  // {
  //   questionNumber: 4,
  //   topic: "第四題",
  //   description: "如何保證中藥貿易產品的真確性？",
  //   option: [
  //     "A:	電話查詢",
  //     "B:	透過電子真偽認證標籤及連結商品線上交易平台",
  //     "C:	電子郵件確認",
  //     "D:	查詢中醫院",
  //   ],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 5,
  //   topic: "5.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 6,
  //   topic: "6.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 7,
  //   topic: "7.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 8,
  //   topic: "8.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 9,
  //   topic: "9.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
  // {
  //   questionNumber: 10,
  //   topic: "10.What do you want to be?",
  //   description:
  //     "She reached her goal, exhausteD: Even more chilling to her was that the euphoria that she thought she'd feel upon reaching it wasn't there. Something wasn't right. Was this the only feeling she'd have for over five years of hard work?",
  //   option: ["A", "B", "C", "D"],
  //   Answer: "B",
  // },
];

export default Questions;
